var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column content-box"},[_c('woot-button',{attrs:{"color-scheme":"success","class-names":"button--fixed-right-top","icon":"add-circle"},on:{"click":function($event){return _vm.openAddPopup()}}},[_vm._v("\n    "+_vm._s(_vm.$t('AGENT_MGMT.HEADER_BTN_TXT'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"small-8 columns with-right-space "},[(_vm.uiFlags.isFetching)?_c('woot-loading-state',{attrs:{"message":_vm.$t('AGENT_MGMT.LOADING')}}):_c('div',[(!_vm.agentList.length)?_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('AGENT_MGMT.LIST.404'))+"\n        ")]):_c('table',{staticClass:"woot-table"},[_c('tbody',_vm._l((_vm.agentList),function(agent,index){return _c('tr',{key:agent.email},[_c('td',[_c('thumbnail',{staticClass:"columns",attrs:{"src":agent.thumbnail,"username":agent.name,"size":"40px","status":agent.availability_status}})],1),_vm._v(" "),_c('td',[_c('span',{staticClass:"agent-name"},[_vm._v("\n                  "+_vm._s(agent.name)+"\n                ")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(agent.email))])]),_vm._v(" "),_c('td',[_c('span',{staticClass:"agent-name"},[_vm._v("\n                  "+_vm._s(_vm.$t(
                      ("AGENT_MGMT.AGENT_TYPES." + (agent.role ? agent.role.toUpperCase() : ''))
                    ))+"\n                ")]),_vm._v(" "),(agent.confirmed)?_c('span',[_vm._v("\n                  "+_vm._s(_vm.$t('AGENT_MGMT.LIST.VERIFIED'))+"\n                ")]):_vm._e(),_vm._v(" "),(!agent.confirmed)?_c('span',[_vm._v("\n                  "+_vm._s(_vm.$t('AGENT_MGMT.LIST.VERIFICATION_PENDING'))+"\n                ")]):_vm._e()]),_vm._v(" "),_c('td',[_c('div',{staticClass:"button-wrapper"},[(_vm.showEditAction(agent))?_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('AGENT_MGMT.EDIT.BUTTON_TEXT')),expression:"$t('AGENT_MGMT.EDIT.BUTTON_TEXT')",modifiers:{"top":true}}],attrs:{"variant":"smooth","size":"tiny","color-scheme":"secondary","icon":"edit","class-names":"grey-btn"},on:{"click":function($event){return _vm.openEditPopup(agent)}}}):_vm._e(),_vm._v(" "),(_vm.showDeleteAction(agent))?_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('AGENT_MGMT.DELETE.BUTTON_TEXT')),expression:"$t('AGENT_MGMT.DELETE.BUTTON_TEXT')",modifiers:{"top":true}}],attrs:{"variant":"smooth","color-scheme":"alert","size":"tiny","icon":"dismiss-circle","class-names":"grey-btn","is-loading":_vm.loading[agent.id]},on:{"click":function($event){return _vm.openDeletePopup(agent, index)}}}):_vm._e()],1)])])}),0)])])],1),_vm._v(" "),_c('div',{staticClass:"small-4 columns"},[_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
          _vm.useInstallationName(
            _vm.$t('AGENT_MGMT.SIDEBAR_TXT'),
            _vm.globalConfig.installationName
          )
        ),expression:"\n          useInstallationName(\n            $t('AGENT_MGMT.SIDEBAR_TXT'),\n            globalConfig.installationName\n          )\n        "}]})])]),_vm._v(" "),_c('woot-modal',{attrs:{"show":_vm.showAddPopup,"on-close":_vm.hideAddPopup},on:{"update:show":function($event){_vm.showAddPopup=$event}}},[_c('add-agent',{attrs:{"on-close":_vm.hideAddPopup,"enabled-features":_vm.enabledFeatures}})],1),_vm._v(" "),_c('woot-modal',{attrs:{"show":_vm.showEditPopup,"on-close":_vm.hideEditPopup},on:{"update:show":function($event){_vm.showEditPopup=$event}}},[(_vm.showEditPopup)?_c('edit-agent',{attrs:{"id":_vm.currentAgent.id,"name":_vm.currentAgent.name,"type":_vm.currentAgent.role,"email":_vm.currentAgent.email,"phone":_vm.currentAgent.phone_number,"extension":_vm.currentAgent.phone_extension,"on-close":_vm.hideEditPopup,"enabled-features":_vm.enabledFeatures}}):_vm._e()],1),_vm._v(" "),_c('woot-delete-modal',{attrs:{"show":_vm.showDeletePopup,"on-close":_vm.closeDeletePopup,"on-confirm":_vm.confirmDeletion,"title":_vm.$t('AGENT_MGMT.DELETE.CONFIRM.TITLE'),"message":_vm.$t('AGENT_MGMT.DELETE.CONFIRM.MESSAGE'),"message-value":_vm.deleteMessage,"confirm-text":_vm.deleteConfirmText,"reject-text":_vm.deleteRejectText},on:{"update:show":function($event){_vm.showDeletePopup=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }