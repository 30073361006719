<template>
  <div @mouseenter="changeIcon('solid')" @mouseleave="changeIcon('outline')">
    <button
      v-tooltip="$t('CONTACT_PANEL.HANGUP_CALL')"
      :class="[
        'button',
        `call-button-${callButtonSize}`,
        colorScheme,
        hasOnlyIconClasses,
        variantClasses,
        classNames,
        isExpanded ? 'expanded' : '',
      ]"
      :type="type"
      @click="hangupCall"
    >
      <spinner v-if="isLoading" size="small" />
      <fluent-icon
        :type="showAlternateIcon ? 'solid' : 'outline'"
        icon="call-dismiss"
        size="16px"
        viewbox="0 0 16 16"
        :class="showAlternateIcon ? 'solid-icon' : 'outline-icon'"
      />
      <span v-if="$slots.default" class="button__content">
        <slot />
      </span>
    </button>
  </div>
</template>

<script>
import Spinner from 'shared/components/Spinner';
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
import { DIALING_TYPES } from 'widget/helpers/constants';

export default {
  name: 'HangoutCallButton',
  components: { Spinner },
  mixins: [alertMixin],
  props: {
    type: {
      type: String,
      default: 'submit',
    },
    variant: {
      type: String,
      default: '',
    },
    buttonSize: {
      type: String,
      default: 'large',
    },
    icon: {
      type: String,
      default: '',
    },
    emoji: {
      type: String,
      default: '',
    },
    colorScheme: {
      type: String,
      default: 'primary',
    },
    classNames: {
      type: [String, Object],
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAlternateIcon: false,
    };
  },
  computed: {
    ...mapGetters({
      currentInbox: 'inboxes/getInbox',
      currentChat: 'getSelectedChat',
      currentUser: 'getCurrentUser',
    }),
    buttonClasses() {
      return [
        this.variantClasses,
        this.hasOnlyIconClasses,
        this.size,
        this.colorScheme,
        this.classNames,
        this.isExpanded ? 'expanded' : '',
      ];
    },
    variantClasses() {
      if (this.variant.includes('link')) {
        return `clear ${this.variant}`;
      }
      return this.variant;
    },
    hasOnlyIcon() {
      const hasEmojiOrIcon = this.emoji || this.icon;
      return !this.$slots.default && hasEmojiOrIcon;
    },
    hasOnlyIconClasses() {
      return this.hasOnlyIcon ? 'button--only-icon' : '';
    },
    callButtonSize() {
      return this.isSettingPhoneAndExtension ? 'large' : 'small';
    },
  },
  methods: {
    hangupCall() {
      const userPhoneNumber = this.currentUser.phone_number?.slice(-10);
      const userExtensionNumber = this.currentUser.phone_extension;
      const contactPhoneNumber = this.currentChat.meta.sender.phone_number?.slice(
        -10
      );

      const inboxSettings = this.currentInbox(this.currentChat.inbox_id);
      const dialingType = DIALING_TYPES.EXTENSION;

      const hangupCallParams = {
        userPhoneNumber,
        contactPhoneNumber,
        userExtension: userExtensionNumber,
        accountId: inboxSettings.account_identifier,
        inboxId: inboxSettings.inbox_identifier,
        inboxApiKey: inboxSettings.inbox_api_key,
        conversationId: this.currentChat.id,
        dialingType,
      };

      this.$store
        .dispatch('hangupCallContact', hangupCallParams)
        .then(response => {
          if (response.status === 200) {
            this.showAlert(this.$t('CONTACT_PANEL.HANGUP_CALL_SUCCESS'));
          } else if (response.status === 400) {
            console.error('Error al colgar la llamada:', response);
            this.showAlert(response.message);
          }
        })
        .catch(error => {
          // Manejar el error
          console.error('Error al colgar la llamada:', error);
          this.showAlert(this.$t('CONTACT_PANEL.HANGUP_CALL_ERROR'));
        });
      return true;
    },
    handleClick(evt) {
      this.$emit('click', evt);
    },
    changeIcon(type) {
      this.showAlternateIcon = type === 'solid';
    },
  },
};
</script>

<style lang="scss" scoped>
.solid-icon {
  color: red;
}

.outline-icon {
  color: red;
}

.call-button-small {
  width: 40px !important;
}
</style>
