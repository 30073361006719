var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"mouseenter":function($event){return _vm.changeIcon('solid')},"mouseleave":function($event){return _vm.changeIcon('outline')}}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('CONTACT_PANEL.HANGUP_CALL')),expression:"$t('CONTACT_PANEL.HANGUP_CALL')"}],class:[
      'button',
      ("call-button-" + _vm.callButtonSize),
      _vm.colorScheme,
      _vm.hasOnlyIconClasses,
      _vm.variantClasses,
      _vm.classNames,
      _vm.isExpanded ? 'expanded' : '' ],attrs:{"type":_vm.type},on:{"click":_vm.hangupCall}},[(_vm.isLoading)?_c('spinner',{attrs:{"size":"small"}}):_vm._e(),_vm._v(" "),_c('fluent-icon',{class:_vm.showAlternateIcon ? 'solid-icon' : 'outline-icon',attrs:{"type":_vm.showAlternateIcon ? 'solid' : 'outline',"icon":"call-dismiss","size":"16px","viewbox":"0 0 16 16"}}),_vm._v(" "),(_vm.$slots.default)?_c('span',{staticClass:"button__content"},[_vm._t("default")],2):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }