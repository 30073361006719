<template>
  <div
    @mouseenter="
      isSettingPhoneAndExtension || !isCurrentUserExtensionInUse
        ? changeIcon('solid')
        : changeIcon('outline')
    "
    @mouseleave="
      isDropdownVisible ? changeIcon('solid') : changeIcon('outline')
    "
  >
    <button
      v-tooltip="!isDisabledCall ? $t('CONTACT_PANEL.CALL') : ''"
      :class="[
        'button',
        `call-button-${callButtonSize}`,
        colorScheme,
        hasOnlyIconClasses,
        variantClasses,
        classNames,
        isDisabled ? 'disabled' : '',
        isExpanded ? 'expanded' : '',
      ]"
      :type="type"
      :disabled="isDisabledCall"
      @click="toggleDropdown"
    >
      <spinner v-if="isLoading" size="small" />
      <fluent-icon
        :type="showAlternateIcon ? 'solid' : 'outline'"
        icon="call-connecting"
        size="16px"
        viewbox="0 0 16 16"
        :class="showAlternateIcon ? 'solid-icon' : 'outline-icon'"
      />
      <fluent-icon
        v-if="isSettingPhoneAndExtension"
        icon="call-connecting-chevron"
        size="12px"
        viewbox="0 0 12 12"
        class="chevron-icon-class"
      />
      <span v-if="$slots.default" class="button__content">
        <slot />
      </span>
    </button>

    <div
      v-if="isDropdownVisible && isSettingPhoneAndExtension"
      v-on-clickaway="onClickaway"
      class="dropdown"
      @click="handleDropdownClick"
    >
      <div
        v-for="(option, index) in dropdownOptions"
        :key="index"
        :class="['option', isOptionDisabled(index) ? 'disabled-option' : '']"
        @click="selectOption(index)"
        @mouseenter="changeDropdownIcon(index, 'solid')"
        @mouseleave="changeDropdownIcon(index, 'outline')"
      >
        <fluent-icon
          :type="showDropdownIcon[index] ? 'solid' : 'outline'"
          :icon="option.icon"
          size="16px"
          viewbox="0 0 16 16"
          :class="
            showDropdownIcon[index]
              ? 'solid-option-icon'
              : 'outline-option-icon'
          "
        />
        <span class="option-text">{{ option.text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from 'shared/components/Spinner';
import { mixin as clickaway } from 'vue-clickaway';
import alertMixin from 'shared/mixins/alertMixin';
import { debounce } from 'lodash';
import { mapGetters } from 'vuex';
import { DIALING_TYPES } from 'widget/helpers/constants';

export default {
  name: 'CallButton',
  components: { Spinner },
  mixins: [alertMixin, clickaway],
  props: {
    type: {
      type: String,
      default: 'submit',
    },
    variant: {
      type: String,
      default: '',
    },
    buttonSize: {
      type: String,
      default: 'large',
    },
    icon: {
      type: String,
      default: '',
    },
    emoji: {
      type: String,
      default: '',
    },
    colorScheme: {
      type: String,
      default: 'primary',
    },
    classNames: {
      type: [String, Object],
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showActionsDropdown: false,
      showAlternateIcon: false,
      showDropdownIcon: [false, false],
      isDropdownVisible: false,
      lastCallTime: null,
    };
  },
  computed: {
    ...mapGetters({
      currentInbox: 'inboxes/getInbox',
      currentChat: 'getSelectedChat',
      currentUser: 'getCurrentUser',
    }),
    /**
     * Comprueba si la llamada está deshabilitada.
     *
     * @returns {boolean} True si la llamada está deshabilitada, de lo contrario, false.
     */
    isDisabledCall() {
      return (
        !this.isSettingPhoneAndExtension && this.isCurrentUserExtensionInUse
      );
    },

    /**
     * Comprueba si una opción está deshabilitada.
     *
     * @param {number} index - El índice de la opción a verificar.
     * @returns {boolean} True si la opción está deshabilitada, de lo contrario, false.
     */
    isOptionDisabled() {
      return index => {
        return (
          index === DIALING_TYPES.EXTENSION && this.isCurrentUserExtensionInUse
        );
      };
    },

    /**
     * Comprueba si la extensión del usuario actual está en uso.
     *
     * @returns {boolean} True si la extensión está en uso, de lo contrario, false.
     */
    isCurrentUserExtensionInUse() {
      return this.currentUser.extension_in_use;
    },
    buttonClasses() {
      return [
        this.variantClasses,
        this.hasOnlyIconClasses,
        this.size,
        this.colorScheme,
        this.classNames,
        this.isDisabled ? 'disabled' : '',
        this.isExpanded ? 'expanded' : '',
      ];
    },
    variantClasses() {
      if (this.variant.includes('link')) {
        return `clear ${this.variant}`;
      }
      return this.variant;
    },
    hasOnlyIcon() {
      const hasEmojiOrIcon = this.emoji || this.icon;
      return !this.$slots.default && hasEmojiOrIcon;
    },
    hasOnlyIconClasses() {
      return this.hasOnlyIcon ? 'button--only-icon' : '';
    },
    isSettingPhoneAndExtension() {
      const usePhoneNumberCall = window.chatwootConfig.usePhoneNumberCall === 'true';
      const userPhoneNumber = this.currentUser.phone_number;
      const userExtensionNumber = this.currentUser.phone_extension;

      return userPhoneNumber && userExtensionNumber && usePhoneNumberCall;
    },
    dropdownOptions() {
      return [
        {
          id: 0,
          icon: 'call-connecting-phone',
          text: this.$t('CONTACT_PANEL.CALL_WITH_PHONE'),
        },
        {
          id: 1,
          icon: 'call-connecting-extension',
          text: this.$t('CONTACT_PANEL.CALL_WITH_EXTENSION'),
        },
      ];
    },
    callButtonSize() {
      return this.isSettingPhoneAndExtension ? 'large' : 'small';
    },
  },
  methods: {
    closeDropdown() {
      this.showActionsDropdown = false;
    },
    openDropdown() {
      this.showActionsDropdown = true;
    },
    handleClick(evt) {
      this.$emit('click', evt);
    },
    changeIcon(type) {
      this.showAlternateIcon = type === 'solid';
    },
    /**
     * Cambia el icono del menú desplegable en función del tipo y el índice proporcionados.
     *
     * @param {number} index - El índice de la opción del menú desplegable.
     * @param {string} type - El tipo de icono ('solid' o 'outline').
     */
    changeDropdownIcon(index, type) {
      if (index === DIALING_TYPES.EXTENSION && this.isCurrentUserExtensionInUse)
        return;
      this.$set(this.showDropdownIcon, index, type === 'solid');
    },

    /**
     * Alterna la visibilidad del menú desplegable y actualiza los iconos según la extensión en uso.
     * Si la extensión del usuario actual está en uso, la función no hace nada.
     * Si la extensión no está en uso, se selecciona la opción de extensión.
     */
    toggleDropdown() {
      if (!this.isSettingPhoneAndExtension) {
        if (this.isCurrentUserExtensionInUse) return;
        this.selectOption(DIALING_TYPES.EXTENSION);
      } else {
        this.changeDropdownIcon(0, 'outline');
        this.changeDropdownIcon(1, 'outline');
        this.isDropdownVisible = !this.isDropdownVisible;
      }
    },
    onClickaway() {
      this.clearIcons();
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    handleDropdownClick(event) {
      event.stopPropagation();
    },
    /**
     * Selecciona una opción del menú desplegable y realiza acciones asociadas si no está deshabilitada.
     *
     * @param {number} option - El índice de la opción seleccionada.
     */
    selectOption(option) {
      if (!this.isOptionDisabled(option)) {
        // Borra los iconos y oculta el menú desplegable.
        this.clearIcons();
        this.isDropdownVisible = false;

        // Realiza una validación y llamada a función debounced según la opción seleccionada.
        this.validateCallDebounced(option);
      }
    },
    clearIcons() {
      this.changeIcon('outline');
      this.changeDropdownIcon(0, 'outline');
      this.changeDropdownIcon(1, 'outline');
    },
    /**
     * Realiza las validaciones antes de realizar una llamada.
     * Muestra alertas de error si las validaciones fallan.
     */
    validateCallDebounced: debounce(
      function(option) {
        const userPhoneNumber = this.currentUser.phone_number?.slice(-10);
        const userExtensionNumber = this.currentUser.phone_extension;
        const contactPhoneNumber = this.currentChat.meta.sender.phone_number?.slice(
          -10
        );

        switch (option) {
          case DIALING_TYPES.PHONE:
            if (this.hasRecentCall()) {
              this.showAlert(
                this.$t('CONTACT_PANEL.CALL_WAIT_TIME', {
                  remainingTime: this.remainingCallWaitTime(),
                })
              );
            } else {
              // Aun no definido metodo para marcacion con numero de telefono
            }
            break;

          case DIALING_TYPES.EXTENSION:
            if (this.hasRecentCall()) {
              this.showAlert(
                this.$t('CONTACT_PANEL.CALL_WAIT_TIME', {
                  remainingTime: this.remainingCallWaitTime(),
                })
              );
            } else {
              this.$store.dispatch('setCurrentUserExtensionInUse', 'true');
              this.makeCall(
                userPhoneNumber,
                userExtensionNumber,
                contactPhoneNumber,
                option
              );
            }
            break;
          default:
            console.error('Opción no válida:', option);
            break;
        }
      },
      500,
      { leading: true, trailing: false }
    ),

    /**
     * Realiza una llamada telefónica utilizando los números de teléfono del usuario y el contacto asociados a la conversación actual.
     * @param {string} userPhoneNumber - Número de teléfono del usuario.
     * @param {string} userExtensionNumber - Extensión del usuario.
     * @param {string} contactPhoneNumber - Número de teléfono del contacto.
     */
    makeCall(
      userPhoneNumber,
      userExtensionNumber,
      contactPhoneNumber,
      dialingType
    ) {
      const inboxSettings = this.currentInbox(this.currentChat.inbox_id);
      const callParams = {
        userPhoneNumber,
        contactPhoneNumber,
        userExtension: userExtensionNumber,
        accountId: inboxSettings.account_identifier,
        inboxId: inboxSettings.inbox_identifier,
        inboxApiKey: inboxSettings.inbox_api_key,
        conversationId: this.currentChat.id,
        dialingType,
      };

      this.$store
        .dispatch('callContact', callParams)
        .then(response => {
          if (response.status === 200) {
            this.lastCallTime = Date.now();
            this.showAlert(this.$t('CONTACT_PANEL.CALL_SUCCESS'));
          } else if (response.status === 400) {
            this.showAlert(response.message);
          }
        })
        .catch(error => {
          // Manejar el error de la llamada al contacto
          console.error('Error en la llamada al contacto:', error);
          this.showAlert(this.$t('CONTACT_PANEL.CALL_ERROR'));
        });
    },

    /**
     * Verifica si se hizo una llamada reciente.
     * @returns {boolean} True si se hizo una llamada reciente, false en caso contrario.
     */
    hasRecentCall() {
      if (this.lastCallTime) {
        const elapsedTime = Math.floor((Date.now() - this.lastCallTime) / 1000);
        return elapsedTime < 5;
      }
      return false;
    },

    /**
     * Calcula el tiempo de espera restante antes de la próxima llamada.
     * @returns {number} Tiempo de espera restante en segundos.
     */
    remainingCallWaitTime() {
      if (this.lastCallTime) {
        const elapsedTime = Math.floor((Date.now() - this.lastCallTime) / 1000);
        return Math.max(0, 5 - elapsedTime);
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.solid-icon {
  color: #27282b;
}

.outline-icon {
  color: none;
}

.call-button-small {
  width: 40px !important;
}
.call-button-large {
  width: 58px !important;
}
.chevron-icon-class {
  margin-left: 6px;
}

/* Estilos para el dropdown */
.dropdown {
  position: absolute;
  width: 191px;
  height: 84px;
  padding: 4px;
  border-radius: 8px;
  z-index: 99999;
  background: #ffffff;
  border: 1px solid #ebf0f5;
  box-shadow: 0px 8px 16px 0px #0000001f;
}

.option {
  display: flex;
  height: 36px;
  border-radius: 8px;
  gap: 4px;
  cursor: pointer;
  &:hover {
    background-color: #ebf0f5;
  }
}

.option-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #1b2836;
  font-size: 14px;
  top: 5px;
  left: 16px;
  position: relative;
  height: 31px;
}

.solid-option-icon {
  top: 8px;
  left: 10px;
  position: relative;
  color: #1b2836;
}

.outline-option-icon {
  top: 8px;
  left: 10px;
  position: relative;
  color: #1b2836;
}

/* Estilo para los elementos deshabilitados */
.disabled-option {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
